export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0];

export const dictionary = {
		"/[...lang=locale]/administrate": [8,[2]],
		"/[...lang=locale]/administrate/[tool]": [9,[2]],
		"/[...lang=locale]/administrate/[tool]/analytics-[id]": [11,[2]],
		"/[...lang=locale]/administrate/[tool]/raw-[id]": [12,[2]],
		"/[...lang=locale]/administrate/[tool]/[id]": [10,[2]],
		"/[...lang=locale]/hidden-login": [13,[2]],
		"/[...lang=locale]/newsletters": [~14,[2]],
		"/[...lang=locale]/newsletters/[controlKey]-manage": [15,[2]],
		"/[...lang=locale]/newsletters/[controlKey]-unsub": [16,[2]],
		"/[...lang=locale]/newsletters/[id]-view": [~17,[2]],
		"/[...lang=locale]/reservations": [~18,[2]],
		"/[...lang=locale]/reservations/[controlKey]-cancel": [19,[2]],
		"/[...lang=locale]/search": [20,[2]],
		"/[...lang=locale]/settings": [21,[2,3]],
		"/[...lang=locale]/settings/addresses": [22,[2,3]],
		"/[...lang=locale]/settings/credit-cards": [23,[2,3]],
		"/[...lang=locale]/settings/delete": [24,[2,3]],
		"/[...lang=locale]/settings/emails": [25,[2,3]],
		"/[...lang=locale]/settings/history": [26,[2,3]],
		"/[...lang=locale]/settings/mfa": [27,[2,3]],
		"/[...lang=locale]/settings/newsletters": [28,[2,3]],
		"/[...lang=locale]/settings/notifications": [29,[2,3]],
		"/[...lang=locale]/settings/password": [30,[2,3]],
		"/[...lang=locale]/settings/phones": [31,[2,3]],
		"/[...lang=locale]/settings/push": [32,[2,3]],
		"/[...lang=locale]/settings/sessions": [33,[2,3]],
		"/[...lang=locale]": [4,[2]],
		"/[...lang=locale]/[level1]": [5,[2]],
		"/[...lang=locale]/[level1]/[level2]": [6,[2]],
		"/[...lang=locale]/[level1]/[level2]/[level3]": [7,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';